










import Vue from "vue";
export default Vue.extend({
  name: "InputSearch",
  props: {
    value: {
      type: String,
      default: undefined
    }
  },
  methods: {
    onSearch(value) {
      this.$emit("input-search", value);
    },
    oninput(value) {
      this.$emit("input", value.target.value);
    }
  },
});
